@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}

// Media query mixins
@mixin xs {
  @media (max-width: $bp_xs) {
    @content;
  }
}

@mixin low-sm {
  @media (max-width: $bp_semlow) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $bp_sm) {
    @content;
  }
}

@mixin sm-up {
  @media (min-width: ($bp_sm + 1)) {
    @content;
  }
}

@mixin semi-sm {
  @media (max-width: $bp_semism) {
    @content;
  }
}

@mixin md {
  @media (max-width: $bp_md) {
    @content;
  }
}

@mixin md-up {
  @media (min-width: ($bp_md + 1)) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $bp_lg) {
    @content;
  }
}

@mixin lg-up {
  @media (min-width: ($bp_lg + 1)) {
    @content;
  }
}

@mixin semi-xl {
  @media (max-width: $bp_semixl) {
    @content;
  }
}

@mixin xl {
  @media (max-width: $bp_xl) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: $bp_xxl) {
    @content;
  }
}

@mixin ffd {
  font-family: $display-font;
  font-weight: 700;
}

// transition
@mixin transition($property: all, $time: 0.2s, $easing: ease-out) {
  transition: $property $time $easing 0s;
}

// user avatar backgrounds based on their badge
@mixin avatar-background-color {
  // top rated
  &.badge-style-0::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 71%, rgba(222, 200, 142, 0.4) 100%);
  }

  // very popular
  &.badge-style-1::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 75%, rgba(184, 162, 219, 0.4) 100%);
  }

  // rising talent
  &.badge-style-2::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 75%, rgba(151, 240, 255, 0.4) 100%);
  }

  // newly joined
  &.badge-style-3::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 75%, rgba(203, 202, 216, 0.5) 100%);
  }

  // staff pick
  &.badge-style-4::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 75%, #f6f5f7 100%);
  }
}
