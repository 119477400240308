@import "./variables.scss";
@import "../mixin.scss";

.container {
  .error-block {
    background: $white;
    border-radius: 2.1rem;
    padding: 15rem;
    max-width: 90%;
    max-width: calc(114rem + 64px);
    margin: auto;
    text-align: center;
    display: block;
    position: relative;

    @include md {
      padding: 4rem;
    }

    .error-img {
      height: auto;
      max-width: 100%;

      &.wizard {
        width: 34rem;

        @include md {
          width: 22.4rem;
        }
      }

      &.hat {
        width: 28.1rem;

        @include md {
          width: 19rem;
        }
      }
    }

    .spray-img {
      position: absolute;
      top: 4rem;
      right: 6rem;
      width: 90%;

      @include md {
        position: absolute;
        top: 8rem;
      }
    }

    h1 {
      font-family: $display-font;
      font-size: 10.8rem;
      font-weight: 700;
      line-height: 12.9rem;
      color: $pr-dv;
      margin-bottom: 0;
      margin-top: 4.5rem;

      @include md {
        font-size: 6.4rem;
        line-height: 7.6rem;
      }
    }

    h3 {
      font-family: $display-font;
      font-size: 2.8rem;
      font-weight: 700;
      line-height: 3.4rem;
      color: $ad-ag;
      margin: 0 auto 3rem;
      width: 100%;
      max-width: 55rem;

      @include md {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.1rem;
      }

      &.error-404 {
        margin: 0 auto 4.8rem;
      }
      &.error-500 {
        margin: 0.9rem auto 2rem;
      }
    }

    a {
      padding-left: 2.9rem;
      padding-right: 2.9rem;
    }
  }
}
