// Fonts
$main-font: "Ubuntu", sans-serif;
$display-font: "Joane", serif;

// Sizes
$font-size: 10px;

// Breakpoints
$bp_xs: 375px;
$bp_semlow: 550px;
$bp_semism: 680px;
$bp_sm: 768px;
$bp_md: 1024px;
$bp_lg: 1280px;
$bp_semixl: 1480px;
$bp_xl: 1680px;
$bp_xxl: 1920px;

// Colors
$white: #fff;
$black: #000;

$pr-dv: #332156;
$pr-sl: #7fa8e5;
$pr-mb: #1a1324;
$pr-mw: #f6f5f7;
$bg-gr: #f6f5f7;
$sh-gr: #6f6f87;
$ad-sg: #7176a0;
$ad-ag: #a0a0ba;
$ad-lp: #ddb4ef;
$ad-mg: #cbcad8;

// statuses
$status-offline: #eee;
$status-online: #68dd65;
$status-busy: #fea60c;

// sign colors
$sign-color-aries: #ff8686;
$sign-color-taurus: #ffd6dd;
$sign-color-gemini: #f5f578;
$sign-color-cancer: #d4d4d4;
$sign-color-leo: #f6df61;
$sign-color-virgo: #b6f4b6;
$sign-color-libra: #d1e9f1;
$sign-color-scorpio: #707070;
$sign-color-sagittarius: #cb99cb;
$sign-color-capricorn: #cd8888;
$sign-color-aquarius: #afafff;
$sign-color-pisces: #aefbe2;

$brand-dark-1: #1a1324;

$light-gray: #f5f6fa;
$light-gray-2: #e7e9f2;
$light-gray-3: #f3f3f3;
$light-gray-4: #cbcad8;
$light-gray-5: #c7c7cc;
$light-gray-6: #a0a0ba;
$light-gray-7: #f6f5f7;
$light-gray-8: #a0a0ba;
$light-gray-9: #7176a0;
$light-gray-10: #bcc0cf;
$light-gray-11: #f2f2f2;
$light-gray-12: #fafafa;
$light-gray-13: #dfdfec;
$light-gray-14: #f1f1f7;

$brand-gray-13: #f5f5f5;

$dark-gray: #2b2b2b;
$dark-gray-2: #5e5e5e;
$dark-gray-3: #5c5c5c;
$dark-gray-4: #4f4f4f;
$dark-gray-5: #585858;
$dark-gray-6: #171c2b;
$dark-gray-7: #b3b3b3;
$dark-gray-8: #535353;
$dark-gray-9: #e0e0e0;
$dark-gray-10: #7a8599;
$dark-gray-11: #ddd;
$dark-gray-12: #b8becc;

$gray-blue: #9396ca;
$gray-blue-2: #63669d;
$gray-blue-3: #adb2ca;
$gray-blue-4: #c1c1dd;
$gray-blue-5: #b7b7d4;
$gray-blue-6: #e6e6f1;
$gray-blue-7: #9fabd7;
$gray-blue-8: #9fa5c0;
$gray-blue-9: #5b5798;
$gray-blue-10: #adb1c9;
$gray-blue-11: #5a5d91;
$gray-blue-12: #c4c4c4;
$gray-blue-13: #7a77ba;
$gray-blue-14: #808191;

$mid-gray: #ebecf2;
$mid-gray-2: #dde0ec;
$mid-gray-3: #f2f4fa;
$mid-gray-4: #f5f5fa;
$mid-gray-5: #e3e5f2;
$mid-gray-6: #e6e9f4;
$mid-gray-7: #e2e3ef;
$mid-gray-8: #d5d5d5;
$mid-gray-9: #e1e1e1;
$mid-gray-10: #f5f7fa;
$mid-gray-11: #dadada;

$brand-blue-1: #5051ed;
$brand-blue-2: #5152ef;
$brand-blue-3: #4c90fe;
$brand-blue-4: #5982fb;
$brand-blue-5: #1a1af0;
$brand-blue-6: #3c2598;
$brand-blue-7: #3c62ea;
$brand-blue-8: #4e78f5;
$brand-blue-9: #151775;
$brand-blue-opactity: rgba(112, 167, 250, 0.17);

$brand-dark-blue: #151675;
$brand-dark-blue-2: #1c0b98;
$brand-dark-blue-3: #101298;
$brand-dark-blue-4: #141674;
$brand-dark-blue-5: #4a2aa5;
$brand-dark-blue-6: #141775;
$brand-dark-blue-7: #161775;
$brand-dark-blue-8: #191b67;
$brand-white-blue-5: #6970c5;

$brand-dark-orange: #f75c1f;
$brand-dark-green: #0a8742;
$brand-light-green: #daf9e7;
$brand-green-opacity: rgba(12, 167, 81, 0.08);
$brand-green-1: #27ae60;
$brand-yellow: #ffc34c;
$brand-yellow-2: #e5ba0d;
$brand-yellow-3: #ffd35b;
$brand-yellow-opacity: rgba(248, 202, 41, 0.13);
$brand-magenta: #d648f9;
$brand-pink: #cb49f1;
$brand-pink-2: #ff4ca0;
$brand-light-pink: #ffdcdc;
$brand-red: #ff4e5b;
$brand-red-2: #f33939;

$brand-orange-1: #ffb938;
$brand-orange-2: #eb8b0e;
$brand-orange-3: #e38707;
$brand-orange-4: #ffac2b;
$brand-orange-5: #f58221;
$brand-orange-6: #f38639;
$brand-orange-7: #fd8a4f;
$brand-orange-8: #f68322;
$brand-orange-9: #f59b4a;
$brand-orange-10: #fc8c50;

$brand-light-blue-1: #5ee2ff;
$brand-light-blue-2: #d9f7ff;
$brand-light-blue-3: #63b3fe;
$brand-light-blue-4: #6690ff;
$brand-light-blue-5: #42cef9;
$brand-light-blue-6: #58d2ff;

$brand-purple-1: #413ad4;
$brand-purple-2: #8139d4;
$brand-purple-3: #5b589c;
$brand-purple-4: #5152ef;
$brand-purple-5: #6a3eed;
$brand-purple-6: #5b30b4;
$brand-purple-7: #9649ef;
$brand-purple-8: #8e4bf6;
$brand-purple-9: #8340ea;
$brand-purple-10: #7061c5;
$brand-purple-11: #5b2bbc;
$brand-purple-12: #6e43c3;
$brand-purple-13: #7e3ae8;

$brand-dark-purple-1: #5a1cb9;
$brand-dark-purple-2: #3e3a9b;
$brand-dark-purple-3: #2e2fa7;
$brand-dark-purple-4: #230376;
$brand-dark-purple-5: #0f1164;
$brand-dark-purple-6: #3536b3;
$brand-dark-purple-7: #4610b5;
$brand-dark-purple-8: #141673;
$brand-dark-purple-9: #2f0a9a;
$brand-dark-purple-10: #40279c;
$brand-dark-purple-11: #320984;

$brand-light-purple-1: #f4edfc;
$brand-light-purple-2: #898bf5;
$brand-light-purple-3: #a6a4ca;
$brand-light-purple-4: #ac8bf3;
$brand-light-purple-5: #7776e3;
$brand-light-purple-6: #d3c4ea;
$brand-light-purple-7: #d9c6ff;
$brand-light-purple-8: #745dd0;
$brand-light-purple-9: #e2d1ff;
$brand-light-purple-10: #c7c8f2;

$checkbox-background-on-dark-background: #4752d4;

$brand-red: #ff4e5b;
$brand-red-1: #f44336;
$brand-red-2: #f33939;
$brand-red-3: #f3a0a0;
$brand-red-4: #e74c3c;
$brand-red-5: #dc3545;
$brand-red-6: #bb2d3b;
$brand-red-7: #b02a37;

$brand-btn-call: #8bd81d;
$brand-btn-chat: #fda785;
$brand-btn-video: #6382eb;

$brand-btn-call-inner: #66c20d;
$brand-btn-chat-inner: #ef3413;
$brand-btn-video-inner: #4236f0;

$brand-btn-cc-video: #5178f1;
$brand-btn-cc-video-border: #57a0f4;

$brand-btn-cc-chat: #f3824c;
$brand-btn-cc-chat-border: #f3ba64;

$brand-btn-cc-call: #84d11e;
$brand-btn-cc-call-border: #a4e132;

// Brand colors
$brand-primary: #35a48c;
$brand-success: #27ae60;
$brand-warning: #e67e22;
$brand-danger: #e74c3c;
$brand-info: #228fb7;

// Gradients and Shadows
$gradients: (
  "service-btn-chat": linear-gradient(180deg, #77adff 0%, #5b8fdd 100%),
  "service-btn-call": linear-gradient(0deg, #1fb981 0%, #21d8a1 100%),
  "service-btn-video": linear-gradient(180deg, #ddb4ef 0%, #c48fdb 100%),
  "btn-orange": linear-gradient(to top, $brand-orange-2, $brand-orange-1),
  "btn-orange-border": linear-gradient(180deg, $brand-orange-2, $brand-orange-10),
  "btn-primary-border": linear-gradient(to right, #687bf4 0%, #687bf4 20%, #57fdff 50%, #687bf4 80%, #687bf4 100%),
  "btn-primary-bg-1": linear-gradient(to top, #6f1de9, #a46dff),
  "btn-primary-bg-2": linear-gradient(to bottom, #8234f8, #8234f8),
  "btn-primary-bg-3": linear-gradient(#5fa5f7, #5fa5f7),
  "btn-slider-active": linear-gradient(0deg, rgba(233, 204, 69, 0) 0%, #e9cc45 100%),
  "btn-slider-active-inner": linear-gradient(180deg, #ffb938 0%, #eb8b0e 100%),
  "free-trial-badge": linear-gradient(to bottom, #ffb137, #eb940a),
  "status-occupied": linear-gradient(to top, #ff3131, #ff7256),
  "status-free": linear-gradient(to top, #0ca751, #24dc76),
  "text-rating-1": linear-gradient(to top, #ef9414, #f8ca29),
  "text-rating-2": linear-gradient(to bottom, #f8c828, #f8c828),
  "text-rating-golden": linear-gradient(0deg, #ffffff 0%, #f8ca29 100%),
  "why-before-1": linear-gradient(to bottom, #4ca4fe, #4188f9),
  "why-before-2": linear-gradient(to top, #4c90fe, #4c90fe),
  "why-after-1": linear-gradient(to bottom, #6768ff, #3e3fe1),
  "why-after-2": linear-gradient(to top, #5152ef, #5152ef),
  "pagination": linear-gradient(to top, #ff8352, #e9cc45),
  "horoscope-icon": linear-gradient(to top, #e1e9fe, #ffffff),
  "dashboard-tabs": linear-gradient(to bottom, #f4a84b, #fe8751),
  "deposit-gift": linear-gradient(56deg, #5a9eff, #4a6ef3),
  "dot-active": linear-gradient(0deg, rgba(203, 73, 241, 0) 0%, rgba(203, 73, 241, 0) 20%, #cb49f1 100%),
  "btn-call": linear-gradient(0deg, rgba(189, 233, 69, 0) 0%, rgba(#bde945, 0.52) 100%),
  "btn-msg": linear-gradient(0deg, rgba(233, 204, 69, 0) 0%, rgba(#e9cc45, 0.52) 100%),
  "btn-video": linear-gradient(0deg, rgba(75, 188, 253, 0) 0%, rgba(#4bbcfd, 0.52) 100%),
  "footer-social-btn": radial-gradient(circle at center, #4163f5cc, transparent 25px),
);

$shadows: (
  "btn": 0px 21px 37px 0 rgba(46, 2, 126, 0.39),
  "btn-inline": 0px 18px 27px 0 rgba(54, 32, 216, 0.69),
  "btn-orange": 0px 14px 20px 0 rgba(197, 77, 12, 0.3),
  "btn-shadow": 0px 10px 15px 0 rgba(156, 156, 174, 0.21),
  "free-trial-badge": 0px 12px 15px 0 rgba(46, 42, 134, 0.69),
  "free-trial-badge-sm": 0px 6px 8px 0 rgba(46, 42, 134, 0.69),
  "satisfaction-badge": 0px 28px 30px 0 rgba(23, 23, 118, 0.64),
  "trustpilot-badge": 0 0 35px 0 #4094ff,
  "psychic-block": 0 24px 30px 0 rgba(62, 58, 155, 0.12),
  "why-item": 0px 19px 40px 0 rgba(62, 58, 155, 0.2),
  "footer-bottom": inset 0px 1px 2px 0 rgba(77, 120, 255, 0.63),
  "footer-social-btn": 0 0 50px 5px rgba(2, 17, 138, 0.7),
  "horoscope-icon": 0px 28px 43px 0 rgba(62, 56, 191, 0.23),
  "top-dropdowns": 0px 67px 106px 0 rgba(28, 14, 63, 0.72),
  "pagination": 0 3px 6px 0 rgba(62, 58, 155, 0.33),
  "deposit-gift": 0px 10px 15px 0 rgba(74, 110, 243, 0.29),
  "deposit-option": 0px 10px 15px 0 rgba(156, 156, 174, 0.21),
  "h1-glow": 0 0 18px rgba(255, 255, 255, 0.67),
  "mobile-bar": 0px -26px 66px 0 rgba(181, 187, 211, 0.47),
  "homepage-hero-h1": 0 6px 6px rgba(46, 20, 107, 0.45),
  "horoscope-item": 0px 12px 60px rgba(62, 58, 155, 0.15),
  "single-psychic-head": 0px 1.81px 14px rgba(62, 58, 155, 0.21),
  "user-flow-title": 0px 6px 6px rgba(46, 20, 107, 0.45),
  "video-chat-modal": 0px 4px 20px rgba(51, 33, 86, 0.04),
  "history-table": 0px 0.81px 6px rgba(62, 58, 155, 0.09),
  "faq-item": 0px 10px 15px rgba(156, 156, 174, 0.212),
  "lp-why-block": 0px 66px 95px rgba(126, 58, 232, 0.24),
  "advisor-btn": 0px 3px 5px rgba(66, 54, 240, 0.2),
  "category-btn": 0px 26px 108px rgba(62, 58, 155, 0.06),
);
